import {
  AreaChartOutlined,
  BarChartOutlined,
  DeleteOutlined,
  HomeOutlined,
  LikeOutlined,
  PieChartOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  StarOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import React from "react";

import CUBE from "../assets/cube-logo.svg";
import configData from "../config.json";
import AccountDeletion from "../screens/Account deletion";
import AdminType from "../screens/AdminType";
import Analytic from "../screens/Analytic";
import AppReports from "../screens/AppReports";
import Booking from "../screens/Booking";
import Business from "../screens/Business";
import businessCard from "../screens/Bussiness Card";
import Centre from "../screens/Centre";
import Challenge from "../screens/Challenge/Challenge";
import Company from "../screens/Company";
import CoursePrice from "../screens/CoursePrices";
import CubeClubWarranty from "../screens/Cube club";
import Invoice from "../screens/Cube club/Invoice";
import Products from "../screens/Cube club/Products";
import HomeGym from "../screens/Cube club/homeGym";
import Leads from "../screens/CubeClubLeads/Leads";
import Curriculum from "../screens/Curriculum";
import {
  default as Experties,
  default as Expertise,
} from "../screens/Expertise";
import Experts from "../screens/Experts";
import Brand from "../screens/Influencer";
import MarketPlaceReviews from "../screens/Market Place Reviews/marketPlaceReviews";
import Offerings from "../screens/Offerings";
import ProfileStatus from "../screens/ProfileStatus";
import RentalHGS from "../screens/Rental HGS";
import Reviews from "../screens/Reviews";
import ShopifyDiscounts from "../screens/ShopifyDiscounts";
import Metrics from "../screens/TV";
import Users from "../screens/Users/Users";
import Verification from "../screens/Verification";

const allRoutes = [
  {
    title: "Home",
    key: "analytics",
    component: Analytic,
    icon: <HomeOutlined />,
    default: true,
  },

  {
    title: "Analytics",
    key: "users",
    component: Users,
    icon: <BarChartOutlined />,
    default: true,
    children: [
      {
        label: "  All Users",
        key: "users",
        component: Users,

        default: true,
      },
    ],
  },
  {
    title: "Customer Delight",
    key: "customerDelight",

    icon: <LikeOutlined />,
    default: true,
    children: [
      {
        title: "Account deletion",

        label: "  Account deletion",
        key: "accountDeletion",
        component: AccountDeletion,
      },
      {
        title: "App reports",
        label: "  App reports",
        key: "appReports",
        component: AppReports,
      },
    ],
  },
  {
    title: "Cube Club",
    key: "cubeclubgrp",
    component: CubeClubWarranty,
    icon: (
      <img
        src={CUBE}
        style={{
          width: 22,
          marginLeft: -4,
        }}
      />
    ),

    default: true,
    children: [
      {
        title: "Web Warranty",

        label: "  Web Warranty",
        key: "cubeclub",
        component: CubeClubWarranty,
        default: true,
      },
      {
        title: "Products",

        label: "  Products",
        key: "cubeclubProducts",
        component: Products,
        default: true,
      },
      {
        title: "App Warranty Approval",

        label: "  App Warranty Approval",
        key: "invoice",
        component: Invoice,
        default: true,
      },
      {
        title: "Home Gym Setup",
        label: "  Home Gym Setup",
        key: "homeGym",
        component: HomeGym,
        default: true,
      },
      {
        title: "Cube Club Leads",
        label: "  Cube Club Leads",
        key: "leads",
        component: Leads,
        default: true,
      },
      {
        title: "Rental HGS",
        label: "  Rental HGS",
        key: "rentalHgs",
        component: RentalHGS,
        default: true,
      },
      {
        title: "Shopify Discounts",
        label: "  Shopify Discounts",
        key: "discounts",
        component: ShopifyDiscounts,
        default: true,
      },
    ],
  },
  {
    child: 0,
    title: "Dashboard",
    key: "tv_dashboard",
    component: Metrics,
    icon: <AreaChartOutlined />,

    default: true,
  },

  {
    child: 1,
    title: "Account deletion",
    key: "accountDeletion",
    component: AccountDeletion,
    icon: <DeleteOutlined />,
  },
  {
    child: 1,
    title: "Cube Club",
    key: "cubeclub",
    component: CubeClubWarranty,
    icon: <CUBE />,
    default: true,
  },
  {
    child: 1,
    title: "Cube Club Products",
    key: "cubeclubProducts",
    component: Products,
    icon: <SafetyCertificateOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "App Warranty Approval",
    key: "invoice",
    component: Invoice,
    icon: <SafetyCertificateOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Home Gym Setup",
    key: "homeGym",
    icon: <SafetyCertificateOutlined />,
    component: HomeGym,
    default: true,
  },

  {
    child: 0,
    title: "Market Place Reviews",
    key: "review",
    component: MarketPlaceReviews,
    icon: <StarOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Cube Club Leads",
    key: "leads",
    component: Leads,
    icon: <StarOutlined />,

    default: true,
  },
  {
    child: 1,
    title: "Rental HGS",
    key: "rentalHgs",
    component: RentalHGS,
    icon: <TrophyOutlined />,

    default: true,
  },
  {
    child: 1,
    title: "App reports",
    key: "appReports",
    component: AppReports,
    icon: <TrophyOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Shopify Discounts",
    key: "discounts",
    component: ShopifyDiscounts,
    icon: <TrophyOutlined />,
    default: true,
  },
];

export const routes = configData.routes.map(
  (r) => (r = { ...r, ...allRoutes.find((a) => a.key === r.key) })
);
