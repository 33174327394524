import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

import { DatePicker } from "antd";
import { Option } from "antd/lib/mentions";
import { ImageUpload } from "../../components/ImageUpload";
import configData from "../../config.json";

export default function Experts({ write = 0 }) {
  const waitlistedTotal = 0;
  const nonWaitlistedTotal = 0;

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [certCount, setCertCount] = useState(1);
  const [editId, setEditId] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [status, setStatus] = useState({});
  const [edit, setEdit] = useState({});
  const [pdfView, setPdfView] = useState();
  const [certApproval, setCertApproval] = useState("");
  const [certId, setCertId] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [waitListType, setWaitListType] = useState(null);
  const [verifiedType, setVerifiedType] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCreate, setisCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    getUsersList();
    getCategoryList();
  }, []);

  //Category types
  const getCategoryList = async () => {
    const payload = {
      api_name: "getCategoryList",
      admin_type_id: 1,
    };
    setLoading(true);
    const res = await getApi({ ...payload }, "getCategoryList", "user/");
    if (res.success) {
      setCategoryList(res.data);
    }
    setLoading(false);
  };
  const isMaxValues = selectedValues?.length === 3;

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);

  const uploadChallengeImage = async (user_id) => {
    if (activeImage !== null) {
      var formdata = new FormData();

      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadProfileImage");
      formdata.append("user_id", user_id);

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadProfileImage`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          getUsersList();
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getUsersList();
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // setFormValues(values);
    isEdit ? createUser({ user_id: selected?.id }) : createUser();
    console.log("Received values of form: ", values);
    console.log("Received values of form: ", form.getFieldValue());
  };

  //user creation
  const createUser = async (data = {}) => {
    const payload = {
      ...data,
      api_name: "createUser",
      ...form.getFieldValue(),
      dob: form.getFieldValue().dob.format("MM/DD/YYYY"),
    };
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 9 },
      "createUser",
      "dashboard/"
    );
    if (res.success) {
      // setUserList(res.data);
      resetFunc();

      setTotal(res.meta?.total);
      uploadChallengeImage(res?.data[0]?.user_id);
    }
    setLoading(false);
  };

  const verifyusername = async () => {
    const payload = isEdit
      ? {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
          user_id: selected?.id,
        }
      : {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
        };
    const res = await getApi(
      { ...payload, role_id: 9, admin_type_id: 2 },
      "verifyusername",
      "user/"
    );
    if (res.data) {
      return true;
    }
  };

  const resetFunc = () => {
    setSelected({});
    setIsSidebarOpen(false);
    setisCreate(false);
    setIsEdit(false);
    form.resetFields();
    setActiveImage(null);
    setActiveIcon("");
  };

  form.setFieldsValue({
    first_name: edit.first_name,
    last_name: edit.last_name,
    username: edit.username,
    mobile: edit.mobile,
    gender: edit.gender,
  });

  const columns = [
    {
      title: "Profile",
      dataIndex: "user_profile_image",
      key: "user_profile_image",
      align: "center",
      render: (text) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<UserOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, object) => (
        <div style={{ width: "max-content" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (t) => (
        <span style={{ textTransform: "capitalize" }}>{t ? t : "-"}</span>
      ),
    },

    // {
    //   title: "Verification status",
    //   dataIndex: "profile_verified",
    //   key: "profile_verified",
    //   align: "center",
    //   filters: [
    //     {
    //       text: "Verified",
    //       value: 1,
    //     },
    //     {
    //       text: "Unverified",
    //       value: 0,
    //     },
    //   ],
    //   render: (t) => (
    //     <div
    //       className="status-container"
    //       style={{ background: t === 1 ? "#DAFFDA" : "#FFE2E2" }}
    //     >
    //       <div
    //         className="status-holder"
    //         style={{ color: t === 1 ? "#08A908" : "#FF0000" }}
    //       >
    //         {t === 1 ? "Verified" : "Unverified"}
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Waitlist Status",
    //   dataIndex: "is_waitlisted",
    //   key: "is_waitlisted",
    //   align: "center",
    //   filters: [
    //     {
    //       text: "Waitlisted",
    //       value: 1,
    //     },
    //     {
    //       text: "Approved",
    //       value: 0,
    //     },
    //   ],
    //   render: (t) => (
    //     <div
    //       className="status-container"
    //       style={{ background: t === 0 ? "#DAFFDA" : "#FFE2E2" }}
    //     >
    //       <div
    //         className="status-holder"
    //         style={{ color: t === 0 ? "#08A908" : "#FF0000" }}
    //       >
    //         {t === 1 ? "Waitlisted" : "Approved"}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Joining Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) =>
        text ? (
          <div style={{ width: "max-content" }}>{DateFormat(text)}</div>
        ) : (
          "-"
        ),
    },
  ];

  const certificateColumns = [
    {
      title: "Service",
      dataIndex: "user_category_name",
      key: "user_category_name",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "is_verified",
      key: "is_verified",
      align: "center",
      render: (text, object) => (
        <>
          {
            <Select
              onChange={(value) => setCertApproval(value)}
              defaultValue={[
                {
                  value: object.status,
                  label: object.status,
                },
              ]}
              options={[
                {
                  value: "verified",
                  label: "Verified",
                },
                {
                  value: "rejected",
                  label: "rejected",
                },
                {
                  value: "processing",
                  label: "Processing",
                },
              ]}
            />
          }
        </>
      ),
    },
    {
      title: "Certificate",
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (text) => (
        <>
          {text.slice(-3) === `pdf` ? (
            <Button
              onClick={() => {
                setPdfView(text);
              }}
            >
              PDF
            </Button>
          ) : (
            <Image height={40} src={text} />
          )}
        </>
      ),
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) =>
        text ? (
          <div style={{ width: "max-content" }}>
            {dayjs(text).format("DD MMM, YYYY h:mm A")}
          </div>
        ) : (
          "-"
        ),
    },
  ];

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 9, admin_type_id: 1 },
      "getUsersList",
      "user/"
    );
    if (res.data) {
      setSelectedRowKeys([]);
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const getCertificateList = async (expert = {}) => {
    setSelected({ ...expert, loading: true });
    const res = await getApi(
      { user_id: expert?.id },
      "getCertificateList",
      "user/"
    );

    if (res.data) {
      setSelected({ ...expert, certificates: [...res.data] });
      setCertCount(res.meta?.total);
    } else setSelected(expert);
  };

  const aproveCertificates = async (certId, status) => {
    setCertificates({ ...certificates, loading: true });
    let is_verified = 0;
    status === "verified" ? (is_verified = 1) : (is_verified = 0);
    let msg = "Verification status updated";
    const res = await saveApi(
      {
        user_id: selected.id,
        certificate_id: certId,
        is_document_verified: is_verified,
        status,
      },
      msg,
      "verifyUserDocs",
      "dashboard/"
    );
    if (res) {
      getCertificateList(selected);
    }
    setCertificates([]);
    setCertApproval("");
  };

  const getBankDetails = async (expert = {}) => {
    setBankDetails(selected);
    setSelected({ ...expert, loading: true });
    let newSelected;
    const res = await getApi(
      { expert_id: expert?.expert_id },
      "getBankDetails",
      "dopamine/"
    );
    if (res.data?.bank_id) {
      setSelected({ ...expert, ...res.data });
      newSelected = { ...expert, ...res.data };
    } else setSelected(expert);

    return newSelected;
  };

  const approveBankHandler = async () => {
    if (accountId === null || accountId === "") {
      message.error("Please provide Razorpay ID", 2);
    } else {
      setBankDetails({ ...bankDetails, loading: true });
      const res = await saveApi(
        {
          bank_id: bankDetails.bank_id,
          is_approved: bankDetails.is_approved ? 0 : 1,
          account_id: accountId,
        },
        bankDetails.is_approved ? "Bank unapproved" : "Bank approved",
        "approveBankDetails",
        "dashboard/"
      );
      if (res) {
        setBankDetails({
          ...bankDetails,
          loading: false,
          is_approved: bankDetails.is_approved ? 0 : 1,
          account_id: accountId,
        });
        setEditId(false);
      } else setBankDetails({ ...bankDetails, loading: false });
    }
  };

  const updateStatusHandler = async () => {
    setSelected({ ...selected, loading: true });

    const res = await (status.action === "badge-verify"
      ? saveApi(
          { user_id: selectedRowKeys, profile_verified: 1 },
          "Profile verified",
          "verifyBadge",
          "dashboard/"
        )
      : status.action === "badge-unverify"
      ? saveApi(
          { user_id: selectedRowKeys, profile_verified: 0 },
          "Profile unverified",
          "verifyBadge",
          "dashboard/"
        )
      : status.action === "un-waitlist"
      ? saveApi(
          { user_id: selectedRowKeys, is_waitlisted: 0 },
          "Profile Approved From Waitlist",
          "approveWaitlisted",
          "dashboard/"
        )
      : saveApi(
          { user_id: selectedRowKeys, is_waitlisted: 1 },
          "Profile added to Waitlist",
          "approveWaitlisted",
          "dashboard/"
        ));

    if (res) {
      setSelected({ ...selected, loading: false });

      waitListType === null || verifiedType === null
        ? getUsersList()
        : getUsersList({ is_waitlisted: waitListType });

      setSelected({});
      setPopup(false);
      setStatus({});
    }
  };

  const updateProfileHandler = async () => {
    const values = await form.validateFields();
    let msg = "Profile not updated succesfully";
    if (edit.id) {
      msg = "Profile update seccesfully";
    }
    const res = await saveApi(
      {
        user_id: edit?.id,
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        username: values?.username,
        dob: values?.dob,
        gender: values?.gender,
      },
      msg,
      "editProfile",
      "user/"
    );

    if (res) {
      getUsersList();
    }
    setEdit({});
    setPopup(false);
  };

  useEffect(() => {
    setAccountId(selected?.account_id);
  }, [selected]);

  return (
    <>
      {(status.action === "badge-verify" ||
        status.action === "badge-unverify") && (
        <Popup
          saveText={selected.profile_verified ? "Yes, Unverify" : "Yes, Verify"}
          title="Profile verification"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelectedRowKeys([]);
            setSelected({});
            setPopup(false);
            setStatus({});
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to{" "}
            {status.action === "badge-verify" ? "verify " : "unverify "}
            {selectedRowKeys.length === 1 ? "this profile " : "these profiles "}
            ?
          </Text>
        </Popup>
      )}

      {(status.action === "un-waitlist" || status.action === "waitlist") && (
        <Popup
          saveText={selected.is_waitlisted ? "Yes, Approve" : "Yes, Proceed"}
          title="Update User Waitlist Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelectedRowKeys([]);
            setSelected({});
            setPopup(false);
            setStatus({});
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to{" "}
            {status.action === "un-waitlist" ? "approve " : "add "}
            {selectedRowKeys.length === 1 ? "this profile " : "these profiles "}
            {status.action === "un-waitlist" ? "from " : "to "} waitlist?
          </Text>
        </Popup>
      )}

      {pdfView && (
        <Popup
          title="Certificates"
          cancelHandler={() => setPdfView()}
          saveHandler={() => setPdfView()}
          saveText="Okay"
          cancelText="Close"
          loading={pdfView.loading}
          visible
        >
          <iframe
            title="pdf"
            style={{
              width: "100%",
              height: "80vh",
              marginTop: "10px",
              borderRadius: "2px",
            }}
            src={pdfView}
            frameBorder="0"
          ></iframe>
        </Popup>
      )}

      {certificates[0]?.certificate_id && (
        <Popup
          title="Certificate approval"
          cancelHandler={() => setCertificates([])}
          saveHandler={() => {
            aproveCertificates(certId, certApproval);
          }}
          saveText="Submit"
          cancelText="Cancel"
          loading={certificates.loading}
          visible
        >
          <TableContainer
            loading={loading}
            columns={write === 0 ? certificateColumns : [...certificateColumns]}
            data={selected.certificates}
            total={certCount}
            searchFor=""
            rowClickHandler={(row) => setCertId(row.certificate_id)}
            dateRangePicker={false}
            exportButton={false}
            functionsHandler={getCertificateList}
          />
        </Popup>
      )}

      {edit.id && (
        <Popup
          title="Expert Details"
          loading={edit?.loading}
          saveHandler={updateProfileHandler}
          cancelHandler={() => {
            setEdit({});
            setPopup(false);
          }}
          saveText="Update"
          cancelText="Cancel"
          visible
        >
          <Form
            requiredMark={false}
            form={form}
            layout="vertical"
            name="company"
            labelCol={{ style: { marginLeft: 12, padding: 0 } }}
          >
            <Row
              gutter={[16, 16]}
              style={{ position: "relative", marginTop: 20 }}
            >
              <Col xs={24} md={12}>
                <Form.Item
                  label="First Name"
                  initialValue={edit?.first_name}
                  name="first_name"
                  key="first_name"
                  rules={[
                    { required: true, message: "Please fill the field" },
                    {
                      min: 3,
                      message: "First Name must be at least 3 characters long!",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Last Name"
                  initialValue={edit?.last_name}
                  name="last_name"
                  key="last_name"
                  rules={[{ required: true, message: "Please fill the field" }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Email"
                  initialValue={edit?.email}
                  name="email"
                  key="email"
                  rules={[
                    { required: true, message: "Please fill all the fields" },
                    {
                      min: 3,
                      message: "First Name must be at least 3 characters long!",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Username"
                  initialValue={edit?.username}
                  name="username"
                  key="username"
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="DOB"
                  initialValue={edit?.dob}
                  name="dob"
                  key="dob"
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Gender"
                  initialValue={edit?.gender}
                  name="gender"
                  key="gender"
                  style={{ marginBottom: 0 }}
                >
                  <Input bordered={true} size="large" placeholder="" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Popup>
      )}

      {bankDetails.bank_id && (
        <Popup
          title="Bank Details"
          loading={bankDetails.loading}
          saveHandler={() => setBankDetails({})}
          cancelHandler={() => setBankDetails({})}
          saveText="Okay"
          cancelText="Close"
          visible
        >
          <Form
            requiredMark={false}
            form={form}
            layout="vertical"
            name="company"
            labelCol={{ style: { marginLeft: 12, padding: 0 } }}
          >
            <Row
              gutter={[16, 16]}
              style={{ position: "relative", marginTop: 20 }}
            >
              <div style={{ position: "absolute", right: 10, top: -25 }}>
                <Switch
                  onChange={approveBankHandler}
                  checked={bankDetails.is_approved ? true : false}
                  loading={bankDetails.loading}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </div>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Account no"
                  initialValue={selected.account_no}
                  name="account_no"
                  key="account_no"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Account type"
                  initialValue={selected.account_type}
                  name="account_type"
                  key="account_type"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Bank name"
                  initialValue={selected.bank_name}
                  name="bank_name"
                  key="bank_name"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Branch"
                  initialValue={selected.branch}
                  name="branch"
                  key="branch"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="IFSC code"
                  initialValue={selected.ifsc_code}
                  name="ifsc_code"
                  key="ifsc_code"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="MICR"
                  initialValue={selected.micr}
                  name="micr"
                  key="micr"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Razorpay ID"
                  initialValue={accountId}
                  name="accountId"
                  key="accountId"
                  style={{ marginBottom: 0 }}
                  onChange={(e) => setAccountId(e.target.value)}
                >
                  <Input
                    readOnly={!editId}
                    size="large"
                    placeholder=""
                    style={{ marginLeft: 10, marginTop: 4 }}
                  />
                </Form.Item>
                {!editId && (
                  <p
                    style={{
                      margin: "8px 0 0 12px",
                      width: "fit-content",
                      color: "#ff4a01",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditId(true)}
                  >
                    {accountId === null || accountId === ""
                      ? "Add ID"
                      : "Update ID"}
                  </p>
                )}
                {editId && (
                  <div style={{ marginLeft: 12, marginTop: 8 }}>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAccountId(selected?.account_id);
                        setEditId(false);
                      }}
                    >
                      Cancel
                    </span>
                    <span
                      style={{
                        marginLeft: 12,
                        color: "#ff4a01",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditId(false);
                      }}
                    >
                      Update
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Popup>
      )}

      <Sidebar
        loading={selected.loading}
        title="Expert details"
        onClose={() => {
          setSelected({});
          resetFunc();
        }}
        visible={isSidebarOpen}
      >
        <LabelValueContainer
          data={
            isCreate
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Creator"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Create
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : isEdit
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Creator"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input disabled type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input disabled type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Col span={8}>
                          <Avatar
                            icon={<UserOutlined />}
                            size={100}
                            alt=""
                            src={selected.profile}
                          />
                        </Col>
                        <Col span={8}>
                          <Text type="label" style={{ fontSize: 11 }}>
                            Name
                          </Text>
                          <Text>{selected.first_name}</Text>
                          <br style={{ marginBottom: "1rem" }} />
                          <Text type="label" style={{ fontSize: 11 }}>
                            Joining Date
                          </Text>
                          <Text>{DateFormat(selected.created_at)}</Text>
                        </Col>

                        <Col span={8} style={{ textAlign: "end" }}>
                          {/* <EditFilled
                            onClick={() => {
                              setIsEdit(true);
                              form.setFieldsValue({
                                dob: moment(selected?.dob, "MM/DD/YYYY"),
                                profile: selected?.profile,
                                first_name: selected?.first_name,
                                username: selected?.username,
                                categories: selected?.categories,
                                gender: selected?.gender,
                                mobile: selected?.mobile,
                                email: selected?.email,
                                weight: selected?.weight,
                                height: selected?.height,
                              });
                            }}
                          /> */}
                        </Col>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                  { label: "Username", value: selected.username },
                  { label: "Mobile Number", value: selected.mobile },
                  { label: "Email", value: selected.email },
                  { label: "Type", value: selected.role },
                  { label: "Gender", value: selected.gender },
                  { label: "Date of Birth", value: selected.dob },
                  {
                    label: "App version",
                    value: selected.app_version || "-",

                    custom: true,
                    fullWidth: true,
                  },
                ]
          }
        />
      </Sidebar>

      <CardContainer hoverable={false}>
        <BreadCrumb title="Experts" />
        <TableContainer
          waitListType={waitListType}
          setWaitListType={setWaitListType}
          setVerifiedType={setVerifiedType}
          verifiedType={verifiedType}
          setStatus={setStatus}
          status={status}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setIsSidebarOpen(true);
            setActiveIcon(row?.profile);
            setSelected(row);

            // const promise = getBankDetails(row);

            // Promise.all([promise]).then((res) =>
            //   getCertificateList(res[0] || row)
            // );
          }}
          data={userList}
          filter="influencer"
          total={total}
          waitlistedTotal={waitlistedTotal}
          nonWaitlistedTotal={nonWaitlistedTotal}
          dateRangePicker="true"
          searchFor="Name, Mobile No."
          functionsHandler={getUsersList}
          // userCreation="Influencer"
          setIsSidebarOpen={setIsSidebarOpen}
          setisCreate={setisCreate}
        />
      </CardContainer>
    </>
  );
}
