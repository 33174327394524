import { EditFilled, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tabs,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { React, useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import { ImageUpload } from "../../components/ImageUpload";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import configData from "../../config.json";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

import TabContainer from "../../components/Tab";
import User from "../../screens/Users/index";
import Influencers from "../Experts/index";
import Creator from "../Influencer/index";
import "./Users.css";
const dayjs = require("dayjs");

export default function Users({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setisCreate] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const isMaxValues = selectedValues?.length === 3;

  useEffect(() => {
    getUsersList();
    getCategoryList();
  }, []);

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);

  const roles = [
    {
      role_id: 3,
      type: "User",
    },
    {
      role_id: 9,
      type: "Expert",
    },
  ];

  // const metrixIcons = [
  //   Total,
  //   expertsMetrics,
  //   brandsMetrics,
  //   centreMetrics,
  //   customerMetrics,
  // ];

  // //inserting icons along with userCounts
  // Object.keys(usersCount).reduce((acc, key, i) => {
  //   acc[key] = [usersCount[key], metrixIcons[i]];
  //   return acc;
  // }, {});

  const allUserColumns = [
    {
      title: "Profile",
      dataIndex: "user_profile_image",
      key: "user_profile_image",
      align: "center",
      render: (text) =>
        text ? (
          <Image
            onClick={(e) => e.stopPropagation()}
            src={text}
            style={{
              borderRadius: "0.5rem",
              height: "24px",
              width: "24px",
            }}
            placeholder={
              <Avatar
                icon={<UserOutlined />}
                shape="square"
                style={{ height: "24px", width: "24px" }}
              />
            }
            preview={text ? true : false}
          />
        ) : (
          <Avatar
            icon={<UserOutlined />}
            shape="square"
            style={{ height: "24px", width: "24px" }}
          />
        ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, object) => (
        <div style={{ width: "150px" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (t) => (
        <span style={{ textTransform: "capitalize" }}>{t ? t : "-"}</span>
      ),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      align: "center",
      key: "dob",
      render: (t) =>
        t ? (
          <div style={{ width: "90px" }}>{dayjs(t).format("DD-MM-YYYY")}</div>
        ) : (
          "-"
        ),
    },
    {
      title: "Account type",
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (t) => t || "-",
    },
    {
      title: "Expertise",
      dataIndex: "categories",
      key: "categories",
      render: (t) =>
        t ? (
          t.length > 3 ? (
            <div className="expertise-container">
              {t.slice(0, 3).map((ele, i) => (
                <div key={i} className="expertise-pill">
                  {categoryList
                    .filter(({ category_id }) => category_id === ele)
                    .map(({ category_name }) => (
                      <div className="expertise-data">{category_name}</div>
                    ))}
                </div>
              ))}
              {"..."}
            </div>
          ) : (
            <div className="expertise-container">
              {t.map((ele, i) => (
                <div key={i} className="expertise-pill">
                  {categoryList
                    .filter(({ category_id }) => category_id === ele)
                    .map(({ category_name }) => (
                      <div className="expertise-data">{category_name}</div>
                    ))}
                </div>
              ))}
            </div>
          )
        ) : (
          "-"
        ),
    },
    // {
    //   title: "Media",
    //   dataIndex: "media",
    //   key: "media",
    //   width: 200,
    //   render: (text) =>
    //     text ? (
    //       <div className="media-container">
    //         {text.map((ele, i) => (
    //           <div className="media-pill">
    //             {ele.media_type === "image" ? (
    //               <Image
    //                 onClick={(e) => e.stopPropagation()}
    //                 src={ele.url}
    //                 style={{
    //                   borderRadius: "3px",
    //                   height: "24px",
    //                   width: "24px",
    //                 }}
    //                 preview={ele.url ? true : false}
    //               />
    //             ) : (
    //               <div className="video-container">
    //                 <img src={PlayIcon} alt="play-icon" />
    //                 <video
    //                   className="media-video"
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     setVideoPlayer(ele?.url);
    //                   }}
    //                 >
    //                   <source src={ele.url} type="video/mp4" />
    //                 </video>
    //               </div>
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //     ) : (
    //       "-"
    //     ),
    // },
  ];

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "getUsersList", "user/");
    res.success && setLoading(false);
    if (res.success) {
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
  };

  const updateStatusHandler = async () => {
    if (selected.id) {
      setSelected({ ...selected, loading: true });
    }
    setSelected({});
  };

  //user creation

  const createUser = async (data = {}) => {
    const payload = {
      ...data,
      api_name: "createUser",
      ...form.getFieldValue(),
      dob: form.getFieldValue().dob.format("MM/DD/YYYY"),
    };
    setLoading(true);
    const res = await getApi({ ...payload }, "createUser", "dashboard/");
    if (res.success) {
      resetFunc();
      setTotal(res.meta?.total);
      uploadChallengeImage(res?.data[0]?.user_id);
    }
    setLoading(false);
  };

  console.log("activeImage", activeImage);
  const uploadChallengeImage = async (user_id) => {
    if (activeImage !== null) {
      var formdata = new FormData();

      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadProfileImage");
      formdata.append("user_id", user_id);

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      console.log("requestOptions", requestOptions);

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadProfileImage`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          getUsersList();
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getUsersList();
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // setFormValues(values);
    isEdit ? createUser({ user_id: selected?.id }) : createUser();
    console.log("Received values of form: ", values);
    console.log("Received values of form: ", form.getFieldValue());
  };

  const verifyusername = async () => {
    const payload = isEdit
      ? {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
          user_id: selected?.id,
        }
      : {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
        };
    const res = await getApi(
      { ...payload, role_id: 10, admin_type_id: 2 },
      "verifyusername",
      "user/"
    );
    if (res.data) {
      return true;
    }
  };

  const resetFunc = () => {
    setSelected({});
    setIsSidebarOpen(false);
    setisCreate(false);
    setIsEdit(false);
    form.resetFields();
    setActiveImage(null);
    setActiveIcon("");
  };

  //Category types
  const getCategoryList = async () => {
    const payload = {
      api_name: "getCategoryList",
      admin_type_id: 1,
    };
    setLoading(true);
    const res = await getApi({ ...payload }, "getCategoryList", "user/");
    if (res.success) {
      setCategoryList(res.data);
    }
    setLoading(false);
  };

  const allUsers = (
    <>
      {selected.id && popup && (
        <Popup
          saveText={selected.is_active ? "Yes, Deactivate" : "Yes, Activate"}
          title="Update User Active Status"
          saveHandler={updateStatusHandler}
          visible={true}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
        >
          <Text bold style={{ fontSize: 18, textTransform: "capitalize" }}>
            Are you sure to {selected.is_active ? "Deactivate" : "Activate"} the
            user {selected.name}?
          </Text>
        </Popup>
      )}

      <Sidebar
        loading={selected.loading}
        title="User details"
        onClose={resetFunc}
        visible={isSidebarOpen}
      >
        <LabelValueContainer
          data={
            isCreate
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Creator"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="role_id"
                            label="Type"
                            rules={[
                              {
                                required: true,
                                message: "Please select type",
                              },
                            ]}
                          >
                            <Select placeholder="Select a type" allowClear>
                              {roles?.map(({ role_id, type }) => (
                                <Option value={role_id}>{type}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Create
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : isEdit
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Creator"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input disabled type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input disabled type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Col span={8}>
                          <Avatar
                            icon={<UserOutlined />}
                            size={100}
                            alt=""
                            src={selected.profile}
                          />
                        </Col>
                        <Col span={8}>
                          <Text type="label" style={{ fontSize: 11 }}>
                            Name
                          </Text>
                          <Text>{selected.first_name}</Text>
                          <br style={{ marginBottom: "1rem" }} />
                          <Text type="label" style={{ fontSize: 11 }}>
                            Joining Date
                          </Text>
                          <Text>{DateFormat(selected.created_at)}</Text>
                        </Col>

                        <Col span={8} style={{ textAlign: "end" }}>
                          <EditFilled
                            onClick={() => {
                              setIsEdit(true);
                              form.setFieldsValue({
                                dob: moment(selected?.dob, "MM/DD/YYYY"),
                                profile: selected?.profile,
                                first_name: selected?.first_name,
                                username: selected?.username,
                                categories: selected?.categories,
                                gender: selected?.gender,
                                mobile: selected?.mobile,
                                email: selected?.email,
                                weight: selected?.weight,
                                height: selected?.height,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                  { label: "Username", value: selected.username },
                  { label: "Mobile Number", value: selected.mobile },
                  { label: "Email", value: selected.email },
                  { label: "Type", value: selected.role },
                  { label: "Gender", value: selected.gender },
                  { label: "Date of Birth", value: selected.dob },
                  {
                    label: "App version",
                    value: selected.app_version || "-",

                    custom: true,
                    fullWidth: true,
                  },
                ]
          }
        />
      </Sidebar>

      <CardContainer hoverable={false}>
        <BreadCrumb title="All Users" />
        <TableContainer
          loading={loading}
          columns={write === 0 ? allUserColumns : [...allUserColumns]}
          rowClickHandler={(row) => {
            setSelected(row);
            setIsSidebarOpen(true);
            setActiveIcon(row?.profile);
          }}
          data={userList}
          total={total}
          parseButton="export"
          dateRangePicker="true"
          filter="userList"
          searchFor="Name, Mobile No."
          functionsHandler={getUsersList}
          userCreation="User"
          setIsSidebarOpen={setIsSidebarOpen}
          setisCreate={setisCreate}
        />
      </CardContainer>
    </>
  );

  console.log("form", form.getFieldValue());

  return (
    <>
      <TabContainer>
        <Tabs.TabPane tab="All" key="1">
          {allUsers}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Expert" key="2">
          <Influencers />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Influencer" key="3">
          <Creator />
        </Tabs.TabPane> */}
        <Tabs.TabPane tab="User" key="5">
          <User />
        </Tabs.TabPane>
      </TabContainer>
    </>
  );
}
